import {defineStore} from 'pinia';
import axios from "axios";
import {useNotificationStore} from "@/store/notificationStore";
import router from "@/router";
import {useStorage} from "@vueuse/core";


export const useLoginStore = defineStore('login', {
  state: () => ({
    token: useStorage('token', ''),
    user: useStorage('user', null)
  }),
  getters: {
    axiosConfig() {
      return {
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${this.token}`,
          "Content-Type": "application/json",
        }
      }
    },
  },
  actions: {
    async getUser() {
      // [GET] /api/user/ => { id, email, name, meta }
      try {
        const res = await axios.get(`/v1/user/`, this.axiosConfig);
        if (res.status === 200) {
          this.user = res.data;
          return res.data;
        }
        else {
          this.user = null;
          return null;
        }
      }
      catch (e) {
        this.user = null;
        return null;
      }
    },
    login(email, password) {
      return new Promise(async (resolve, reject) => {
        const notificationStore = useNotificationStore();

        const formData = new FormData();
        formData.append('username', email);
        formData.append('password', password);

        axios.request({
          method: 'POST',
          url: `/v1/token/`,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        }).then(async res => {
          if (res.status === 200) {
            notificationStore.push('로그인 됨.', 'success');

            this.token = res.data.access_token;
            await this.getUser();
            await router.push({name: 'main'});
            resolve();
          }
        }).catch(err => {
          console.log(err);
          if (err.response) {
            if (err.response.status === 401) {notificationStore.push('이메일 또는 비밀번호가 올바르지 않습니다.', 'error'); return;}
            if (err.code === 'ERR_NETWORK') {notificationStore.push('네트워크 오류', 'error'); return;}
          }
          notificationStore.push('로그인을 하는 도중 오류가 발생했습니다.', 'error');
        });
      });
    },
    register(email, name, password, registerPassword) {
      return new Promise((resolve, reject) => {
        const notificationStore = useNotificationStore();

        axios.post('/v1/user/', {
          email: email,
          name: name,
          password: password,
          register_password: registerPassword,
        }).then(async res => {
          if (res.status === 200) {
            notificationStore.push("회원가입 성공", 'success')
            await router.push('/login')
            resolve();
          }
        }).catch(err => {
          console.log(err);
          if (err.response) {
            if (err.response.status === 401) {notificationStore.push('가입 인증 코드가 올바르지 않습니다.', 'error'); return;}
            if (err.response.status === 409) {notificationStore.push('같은 계정이 이미 존재합니다.', 'error'); return;}
          }
          if (err.code === 'ERR_NETWORK') {notificationStore.push("네트워크 오류", "error"); return;}
          notificationStore.push("회원가입을 하는 도중 오류가 발생했습니다.", "error");
        });
      });
    },
    async logout() {
      const notificationStore = useNotificationStore();

      this.token = '';
      this.user = null;

      await router.push('/login')

      notificationStore.push('로그아웃 됨.', 'success');
    }
  }
});
