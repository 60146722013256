import Vue from 'vue'
import VueRouter from 'vue-router'
import {useLoginStore} from "@/store/loginStore";
import {useNotificationStore} from "@/store/notificationStore";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/LandingPageView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/LoginView')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/login/RegisterView')
  },
  {
    path: '/main',
    name: '',
    meta: {requiresAuth: true},
    component: () => import('../views/MainView.vue'),
    children: [
      {path: '', name: 'main', component: () => import('../views/main/SecretsView.vue')},
      {path: 'shared', name: 'shared', component: () => import('../views/main/SharedView.vue')},
    ]
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {requiresAuth: true},
    component: () => import('../views/profile/ProfileView.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  const loginStore = useLoginStore();
  const notificationStore = useNotificationStore();

  await loginStore.getUser();

  if (loginStore.user && (['login', 'register', 'home'].includes(to.name))) {
    next('/main');
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (loginStore.user) {
      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

export default router
