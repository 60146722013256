<template>
  <v-app style="height: 100vh">
    <!-- 로그인 페이지 -->
    <template v-if="!user">
      <router-view></router-view>
    </template>

    <!-- 메인 페이지 -->
    <template v-else>
      <v-app-bar dark color="grey darken-4" clipped-left app :extended="path.startsWith('/main')">
        <v-app-bar-nav-icon @click.stop="showDrawer = !showDrawer"></v-app-bar-nav-icon>
        <v-toolbar-title>Secret Manager</v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-slot:extension v-if="path.startsWith('/main')">
          <v-tabs v-model="tab" background-color="grey darken-4" dark align-with-title>
            <v-tab to="/main">내 문서</v-tab>
            <v-tab to="/main/shared">나에게 공유됨</v-tab>
          </v-tabs>
        </template>
      </v-app-bar>

      <v-navigation-drawer v-model="drawerModel" :mini-variant.sync="miniDrawer" :permanent="drawer" :clipped="!isMobile" fixed app>
        <v-list>
          <v-list-item to="/main">
            <v-list-item-icon>
              <v-icon>web</v-icon>
            </v-list-item-icon>
            <v-list-item-title>대시보드</v-list-item-title>
          </v-list-item>
          <v-list-item to="/profile">
            <v-list-item-icon>
              <v-icon>person</v-icon>
            </v-list-item-icon>
            <v-list-item-title>프로필</v-list-item-title>
            <v-list-item-content>
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>exit_to_app</v-icon>
            </v-list-item-icon>
            <v-list-item-title>로그아웃</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="showDrawer = !showDrawer">
            <v-list-item-icon>
              <v-icon v-html="miniDrawer ? 'chevron_right' : 'chevron_left'"></v-icon>
            </v-list-item-icon>
            <v-list-item-title>닫기</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-main fluid fill-height style="height: 100%; overflow: hidden;">
        <router-view/>
      </v-main>

      <v-footer class="pa-3" fixed app>
        <v-spacer></v-spacer>
        <span>&copy; Made by Yoongi Kim (yoongi@bhsn.ai)</span>
      </v-footer>
    </template>
    <Notification/>
  </v-app>
</template>

<script>

import {useUiStore} from "@/store/uiStore";
import {useLoginStore} from "@/store/loginStore";
import {storeToRefs} from "pinia";

export default {
  name: 'App',
  components: {
    'Notification': () => import('./components/Notification.vue')
  },
  data: () => ({
    showDrawer: false,
    drawerModel: false,
  }),
  computed: {
    path() {
      return this.$route.path
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm'
    },
    drawer() {
      if (!this.isMobile) {
        return true;
      }
      else {
        return this.showDrawer;
      }
    },
    miniDrawer() {
      if (!this.isMobile) {
        return !this.showDrawer;
      }
      else {
        return false;
      }
    },
  },
  setup() {
    const uiStore = useUiStore();
    const loginStore = useLoginStore();
    const {user} = storeToRefs(loginStore);
    const {tab} = storeToRefs(uiStore);
    return {uiStore, loginStore, user, tab}
  },
  mounted() {
    this.loginStore.getUser();
  },
  methods: {
    logout() {
      this.loginStore.logout();
    },
  },
};
</script>
