import {defineStore} from 'pinia';

function waitUntil(condition, callback, timeout) {
  if (condition()) {
    callback();
  } else {
    setTimeout(function () {
      waitUntil(condition, callback, timeout);
    }, timeout);
  }
}

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    visible: false,
    message: '',
    color: '',  // success, error, info, warning
    queue: [],
  }),
  actions: {
    push(message, color) {
      this.queue.push({message, color});

      waitUntil(
          () => (this.queue.length > 0 && this.queue[0].message === message),
          () => {
            this.visible = true;
            this.message = message;
            this.color = color;
            const timeout = this.color === 'error' ? 3000 : 1000;
            setTimeout(() => {
              this.queue.shift();
            }, timeout);
            waitUntil(
                () => (this.queue.length === 0 || this.queue[0].message !== message),
                () => {
                  this.visible = false;
                })
          },
          100);
    },
    close() {
      this.queue.shift();
    }
  }
});
