import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import {createPinia} from 'pinia'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import axios from "axios";
import 'text-security';

axios.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;


Vue.config.productionTip = false

const pinia = createPinia()

Vue.use(pinia)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
